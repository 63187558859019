import $ from 'jquery';

$(document).ready(function () {
    mobileSearch(); 
    $(window).resize(function(){
        displayDesktopSearch()
      });

      function mobileSearch() {
        if (window.matchMedia('(max-width: 1200px)').matches)
        {
            $('#mobile-search-btn').on('click', function(){
                if($('#search_widget').css("display") == "none") {
                    $('#search_widget').slideDown(100);
                }
                else {
                    $('#search_widget').slideUp(100);
                }
            });
        }

    }

    function displayDesktopSearch() {
        if (window.matchMedia('(min-width: 1199px)').matches)
        {
            if($('#search_widget').css("display") == "none") {
                $('#search_widget').slideDown(100);
            };
        }
    }
});
